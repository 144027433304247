const categories =  [
    {value:"English", label:"en"},
    {value:"French", label:"fr"},
    {value:"Spanish", label:"es"},
    {value:"Hindi", label:"hi"},
    {value:"Japanese", label:"ja"},
    {value:"Russian", label:"ru"},
    {value:"German", label:"de"},
    {value:"Arabic", label:"ar"}
]
export default categories